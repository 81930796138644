<template>
  <div class="flex flex-center" style="width: 500px">
    <vueCropper
      ref="cropper"
      :img="img_url"
      :outputSize="option.size"
      :outputType="option.outputType"
    ></vueCropper>
  </div>
</template>

<script>
import { VueCropper } from "vue-cropper";
export default {
  components: {
    VueCropper,
  },
  data() {
    return {
      option: {
        size: 1,
        outputType: "png",
      },
    };
  },
  mounted() {
    console.log(this.img_url, "img_url");
  },
  props: {
    img_url: {
      type: String,
      default: "",
    },
  },
  methods:{
    init(){
        this.$refs.cropper.startCrop()
    }
  }
};
</script>

<style>
</style>