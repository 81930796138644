<template>
  <div class="">
    <el-card shadow="never">
      <div class="flex ju-be">
        <el-button
          type="primary"
          @click="
            (centerDialogVisible = true),
              (stepsActive = 2),
              (isEdit = 0),
              (fileListZip = []),
              (ruleForm = {}),
              (fileList = []),
              (fileList1 = []),
              (StorageTime = ''),
              (switchSeting = false)
          "
          >创建应用</el-button
        >
        <div class="flex">
          <el-input
            placeholder="请输应用标题"
            prefix-icon="el-icon-search"
            v-model="titleValue"
          >
          </el-input>
          <el-button class="ml-16" type="primary" @click="searchTitle"
            >搜索</el-button
          >
        </div>
      </div>

      <commonTable
        :tableData="tableData"
        :loading="loading"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :currentPage="currentPage"
        :total="total"
      >
        <template v-slot:table>
          <el-table-column prop="id" align="center" label="编号" />
          <el-table-column align="center" label="封面">
            <div slot-scope="scope">
              <img class="w-70 h-56 ra-4" :src="scope.row.icon" alt="" />
            </div>
          </el-table-column>
          <el-table-column align="center" label="应用标题">
            <div slot-scope="scope">
              <a
                class="co-51 size-14 fw-5 txt-udl pointer"
                :href="scope.row.realUrl"
                target="_blank"
                v-if="scope.row.fileUrl"
              >
                {{ scope.row.title }}
              </a>
              <div
                class="co-51 size-14 fw-5 txt-udl pointer"
                v-else
                @click="$message.warning('请先上传应用')"
              >
                {{ scope.row.title }}
              </div>
            </div>
          </el-table-column>
          <el-table-column prop="" align="center" label="分类">
            <div slot-scope="scope">
              {{ scope.row.typeName ? scope.row.typeName : "未分类" }}
            </div>
          </el-table-column>
          <el-table-column prop="remark" align="center" label="备注 " />
          <!-- <el-table-column prop="createBy" align="center" label="上传者">
          </el-table-column>
          <el-table-column align="center" label="占用空间">
            <p slot-scope="scope">
              {{ scope.row.fileSize ? scope.row.fileSize + "kb" : "-" }}
            </p>
          </el-table-column> -->
          <!-- <el-table-column prop="pvCount" align="center" label="访问量PV " /> -->
          <!-- <el-table-column prop="a7" align="center" label="开启加速">
              <div slot-scope="scope">
                <el-switch
                  v-model="scope.row.a7"
                  active-color="$color-1"
                  inactive-color="#ff4949"
                >
                </el-switch>
              </div>
            </el-table-column> -->
          <el-table-column align="center" label="访问状态">
            <div slot-scope="scope">
              <el-popconfirm
                title="是否切换访问状态？"
                @confirm="isSwitch(scope.row)"
              >
                <el-switch
                  class="pointer"
                  disabled
                  slot="reference"
                  v-model="scope.row.status"
                >
                </el-switch>
              </el-popconfirm>
            </div>
          </el-table-column>
          <el-table-column align="center" label="剩余时间 ">
            <div slot-scope="scope">
              {{ scope.row.dueTim ? surplusDay(scope.row.dueTim) + "天" : "-" }}
            </div>
          </el-table-column>
          <el-table-column align="center" label="上传时间 " width="200px">
            <div slot-scope="scope">
              {{ getTimer(scope.row.createTime) }}
            </div>
          </el-table-column>
          <el-table-column width="200px" align="center" label="操作">
            <div slot-scope="scope">
              <el-button type="text" @click="openLog(scope.row, 0)">{{
                scope.row.fileUrl ? "更新" : "上传"
              }}</el-button>
              <el-button type="text" @click="openLog(scope.row, 1)"
                >设置</el-button
              >
              <el-button type="text" @click="openLog(scope.row, 2)"
                >预览</el-button
              >
              <el-popconfirm
                class="ml-12"
                title="是否删除模型？"
                @confirm="openLog(scope.row, 3)"
              >
                <el-button slot="reference" type="text">删除</el-button>
              </el-popconfirm>
            </div>
          </el-table-column>
        </template>
      </commonTable>
    </el-card>
    <el-dialog
      :title="stepsActive == '1' ? '更新应用' : '创建应用'"
      :visible.sync="centerDialogVisible"
      @close="centerDialogVisible = false"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      width="50%"
      class="dialog-boxs"
      center
    >
      <!-- <div class="flex flex-center mb-32" v-if="isEdit == 0">
        <el-steps
          :active="stepsActive"
          finish-status="success"
          style="width: 400px"
        >
          <el-step title="上传zip压缩包"> </el-step>
          <el-step title="应用详情"> </el-step>
        </el-steps>
      </div> -->
      <!-- 上传压缩包 -->
      <el-form v-if="stepsActive == 1">
        <el-form-item label="">
          <el-upload
            class="avatar-uploader upload-zip"
            ref="uploadZip"
            action="https://api-t-2.upload.jquen.com/api/took/card/upload/zip"
            accept=".zip"
            :file-list="fileListZip"
            :on-progress="handProgress"
            :on-success="handleAvatarSuccessZip"
            :before-upload="beforeAvatarUploadZip"
            :on-change="handleChangeZip"
            :auto-upload="false"
            :limit="1"
            :headers="headers"
          >
            <p class="tet-udl pointer">点击上传文件</p>
          </el-upload>
          <div class="co-66">*只能上传zip压缩包，且不超过200M</div>
        </el-form-item>
      </el-form>
      <!-- 应用详情 -->
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        v-if="stepsActive == 2"
      >
        <el-form-item label="标题" prop="title">
          <el-input
            v-model="ruleForm.title"
            placeholder="请输入标题"
          ></el-input>
        </el-form-item>
        <el-form-item label="分类" prop="title">
          <el-select
            v-model="ruleForm.typeId"
            placeholder="请选择应用分类"
            style="width: 100%"
          >
            <el-option
              v-for="item in classList"
              :key="item.typeId"
              :label="item.labelName"
              :value="item.typeId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="应用类型" prop="title" v-show="false">
          <el-radio v-model="ruleForm.blendType" :label="1">zip</el-radio>
          <el-radio v-model="ruleForm.blendType" :label="2">blend</el-radio>
        </el-form-item>
        <!-- <el-form-item label="文件地址" prop="title">
          <el-input disabled v-model="ruleForm.fileUrl" placeholder="文件地址">
            <el-button slot="append" @click="screenshot">截取封面图</el-button>
          </el-input>
        </el-form-item> -->
        <!-- <el-form-item label="文件大小" prop="title">
            <el-input
              disabled
              v-model="ruleForm.fileSize"
              placeholder="文件大小"
            ></el-input>
          </el-form-item> -->
        <el-form-item label="封面" prop="coverImg">
          <el-upload
            :class="{
              'avatar-uploader': true,
              'avatar-coverImg': true,
              disabled: uploadDisabled,
            }"
            ref="uploadIcon"
            :action="$store.state.uploadingUrl"
            list-type="picture-card"
            :on-progress="handProgress"
            :on-success="handleAvatarSuccessCoverImg"
            :on-remove="handleRemove"
            :file-list="fileList"
            :limit="1"
            accept=".png,.jpg"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">
              *格式png、jpg，大小不超过2MB。
            </div>
          </el-upload>
        </el-form-item>
        <div class="xian mb-16"></div>
        <div class="flex mb-16">
          <h3 class="">高级设置</h3>
          <el-switch class="ml-16" v-model="switchSeting"> </el-switch>
        </div>
        <div class="xian mb-16"></div>
        <div v-if="switchSeting">
          <el-form-item label="存储时间" prop="title">
            <el-input
              type="Number"
              v-model="StorageTime"
              oninput="if(value<0)value=0"
              placeholder="请输入存储时间"
            ></el-input>
            <p class="op-5">* 请直接输入数字，单位为‘天’；留空则为永久访问</p>
          </el-form-item>
          <el-form-item label="访问密码" prop="title">
            <el-input
              type="Number"
              v-model="ruleForm.password"
              placeholder="请输入访问密码"
            ></el-input>
            <p class="op-5">* 请输入六位数字密码；留空则关闭密码访问功能</p>
          </el-form-item>
          <el-form-item label="备注" prop="title">
            <el-input
              type="textarea"
              :rows="2"
              v-model="ruleForm.remark"
              placeholder="请填写备注信息"
            ></el-input>
          </el-form-item>
          <el-form-item label="浏览器标题" prop="title">
            <el-input
              v-model="ruleForm.webTitle"
              placeholder="请输入浏览器标题"
            ></el-input>
          </el-form-item>
          <el-form-item label="浏览器图标" prop="title">
            <el-upload
              :class="{
                'avatar-uploader': true,
                'avatar-iconImg': true,
                disabled: uploadDisabled1,
              }"
              :action="$store.state.uploadingUrl"
              list-type="picture-card"
              :on-progress="handProgress"
              :on-success="handleAvatarSuccessIcon"
              :on-remove="handleRemoveIcon"
              :file-list="fileList1"
              :limit="1"
              accept=".ico"
            >
              <i class="el-icon-plus avatar-uploader-icon"></i>
              <div slot="tip" class="el-upload__tip">
                * 请上传宽高为32px的ico图标
              </div>
            </el-upload>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitOk">{{
          stepsActive == 1 ? "上 传" : "确 定"
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisiblePoint"
      width="40%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :modal-append-to-body="false"
    >
      <p class="text-center co-99 size-24 ptb-64">
        正在上传文件，预计剩余时间<span class="co-51">{{ surplusTimer }}s</span>
      </p>
    </el-dialog>
    <!-- 预览 -->
    <el-dialog
      title=""
      :visible.sync="previewDialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      :show-close="false"
      :lock-scroll="false"
      width="425px"
      class="dialog-box"
    >
      <div class="handset-box">
        <h3 class="title">{{ iframeTitle }}</h3>
        <iframe :src="iframeUrl" frameborder="0"></iframe>
        <div class="btn-box">
          <!-- <img
              src="@/assets/images/model-close.png"
              alt=""
              @click="(previewDialogVisible = false), (iframeUrl = '')"
            />
            <a :href="iframeUrl" target="_blank">
              <img src="@/assets/images/model-full.png" alt="" />
            </a>
            <img src="@/assets/images/model-copy.png" alt="" @click="copyUrl" />
            <img src="@/assets/images/model-qr.png" alt="" /> -->
          <div
            class="close"
            @click="(previewDialogVisible = false), (iframeUrl = '')"
          ></div>
          <div class="title" @click="copyUrl"></div>
          <div class="back"></div>
          <div class="qr"></div>
        </div>
      </div>
    </el-dialog>
    <!-- 截取封面图 -->
    <el-dialog
      title="截取封面图"
      :visible.sync="canvasDialogVisible"
      @close="closeCanvas"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      :lock-scroll="true"
      width="1330px"
      center
      @click="getImg"
    >
      <div class="canvas-box">
        <!-- <iframe :src="ruleForm.fileUrl" frameborder="0" id="cavas"></iframe> -->
        <iframe
          :src="jietu_url + '?takeCover_Xfj23kfidos23dDko349J&token=' + isToken"
          frameborder="0"
          id="cavas"
        ></iframe>
        <div class="zhezhao pointer" v-if="zhe_zhao" @click="zhe_zhao = false">
          <div class="co-ff size-18 text-center lh-46">
            <p>{{ point_text1 }}</p>
            <p>{{ point_text2 }}</p>
            <p>{{ point_text3 }}</p>
          </div>
        </div>
        <!-- <div style="width:100%;height:100%;background:red;" id="cavas"></div> -->
        <el-button type="primary" @click="clipSrcPic()">裁剪图片</el-button>
        <!-- <img class="img-box" :src="img_url" alt=""> -->
      </div>
    </el-dialog>
    <!-- 剪裁组件弹窗 -->
    <el-dialog
      title="裁切封面"
      :visible.sync="cropperModel"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="950px"
      center
    >
      <!-- <Copper
          :img_url="img_url"
          @uploadImgSuccess="handleUploadSuccess"
          ref="child"
        >
        </Copper> -->
      <div class="cropper-box">
        <vueCropper
          ref="cropper"
          :img="option.img"
          :outputSize="option.size"
          :outputType="option.outputType"
          :info="option.info"
          :canScale="option.canScale"
          :autoCrop="option.autoCrop"
          :auto-crop-width="option.autoCropWidth"
          :auto-crop-height="option.autoCropHeight"
          :fixed="option.fixed"
          :fixedNumber="option.fixedNumber"
          :full="option.full"
          :fixedBox="option.fixedBox"
          :canMove="option.canMove"
          :canMoveBox="option.canMoveBox"
          :original="option.original"
          :centerBox="option.centerBox"
          :height="option.height"
          :infoTrue="option.infoTrue"
          :maxImgSize="option.maxImgSize"
          :enlarge="option.enlarge"
          :mode="option.mode"
          @imgLoad="imgLoad"
        ></vueCropper>
        <el-button type="primary" @click="isImgOk()">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
  
  <script>
import { VueCropper } from "vue-cropper";
import commonTable from "@/components/common/commonTable";
import Copper from "./components/cropper.vue";
import {
  offLineQueryNo,
  offLineZip,
  blendAdd,
  blendQueryPage,
  blendSwitchExplore,
  blendDeleteById,
  blendUpdate,
  coverGet,
  uploadImg,
  typeQueryPage,
} from "@/api/drawingBed";
import { fetchCompany } from "@/api/login.js";
// import { uploadImg } from '@/api/model'

export default {
  components: {
    commonTable,
    VueCropper,
  },
  data() {
    return {
      point_text1: "将模型拖拽到合适角度后双击进行截图",
      point_text2: "若截图失败，请尝试使用chrome浏览器进行截图！",
      point_text3: "（点击关闭提示）",
      zhe_zhao: true,
      jietu_url: "",
      option: {
        img: "", //裁剪图片的地址
        outputSize: 1, //裁剪生成图片的质量(可选0.1 - 1)
        outputType: "png", //裁剪生成图片的格式（jpeg || png || webp）
        info: true, //裁剪框的大小信息，图片大小信息
        canScale: true, //图片是否允许滚轮缩放
        autoCrop: true, //是否默认生成截图框
        autoCropWidth: "375px", //默认生成截图框宽度
        autoCropHeight: "300px", //默认生成截图框高度
        fixed: true, //是否开启截图框宽高固定比例
        fixedNumber: [5, 4], //截图框的宽高比例
        full: true, //false按原比例裁切图片，不失真
        fixedBox: false, //固定截图框大小，不允许改变
        canMove: true, //上传图片是否可以移动
        canMoveBox: true, //截图框能否拖动
        original: true, //上传图片按照原始比例渲染
        centerBox: false, //截图框是否被限制在图片里面
        height: true, //是否按照设备的dpr 输出等比例图片
        infoTrue: false, //true为展示真实输出图片宽高，false展示看到的截图框宽高
        maxImgSize: 3000, //限制图片最大宽度和高度
        enlarge: 1, //图片根据截图框输出比例倍数
        mode: "", //图片默认渲染方式
      },
      centerDialogVisible: false, //上传应用
      previewDialogVisible: false, //预览
      dialogVisiblePoint: false, //上传倒计时
      canvasDialogVisible: false, //canvas截图
      cropperModel: false, //裁剪框
      stepsActive: 1,
      iframeUrl: "",
      iframeTitle: "",
      fileListZip: [],
      tableData: [],
      tableData1: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 1, //总条数
      bEdit: false,
      ruleForm: {
        name: "",
        date: "",
        url: "",
        blendType: "1",
      },
      rules: {},
      headers: "",
      surplusTimer: 0,
      fileList: [],
      isEdit: 0, //0添加，1设置
      openLogType: 0, //0更新，1设置
      img_url: "",
      isToken: "",
      classList: [],
      titleValue: "",
      uploadTypeList: [
        { title: "zip", id: "1" },
        { title: "blend", id: "2" },
      ],
      StorageTime: "",
      fileList1: [],
      isUploadType: false,
      switchSeting: false,
    };
  },
  computed: {
    uploadDisabled() {
      return this.fileList.length !== 0;
    },
    uploadDisabled1() {
      return this.fileList1.length !== 0;
    },
  },
  mounted() {
    this.headers = {
      token: localStorage.getItem("token"),
      Accept: "application/json;charset=utf-8",
      companyKey: "one-stand",
    };
    this.getData();
    this.isToken = localStorage.getItem("token");
    console.log(this.isToken, "token");
    this.getClassList();
  },
  methods: {
    getInfo() {
      fetchCompany().then((res) => {});
    },
    // 搜索
    searchTitle() {
      this.currentPage = 1;
      this.getData();
    },
    // 获取分类列表
    getClassList() {
      let data = {
        pageNum: 1,
        pageSize: 1000,
      };
      typeQueryPage(data).then((res) => {
        this.classList = res.data.records;
      });
    },
    // 时间格式
    getTimer(time) {
      let data = new Date(time).toLocaleString();
      // return data.getFullYear()
      return data;
    },
    //初始化函数
    imgLoad(msg) {
      console.log(msg, "初始化函数");
    },
    // 裁剪图片
    isImgOk() {
      this.cropperModel = false;
      console.log(this.fileList, "this.$refs.upload.submit();");
      // 获取截图的数据
      this.$refs.cropper.getCropData((data) => {
        this.fileList = [
          {
            name: "img",
            url: data,
          },
        ];
        let value = this.base64ImgtoFile(data);
        const formData = new window.FormData();
        formData.append("file", value);
        uploadImg(formData).then((res) => {
          if (res.code == 200) {
            this.$message.success("上传成功");
            this.ruleForm.icon = "https://cdn-oss.jquen.com/" + res.data;
          } else {
            this.$message({
              message: "上传失败",
              type: "error",
              duration: 1000,
            });
          }
        });
      });
    },
    base64ImgtoFile(dataurl, filename = "file") {
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let suffix = mime.split("/")[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime,
      });
    },
    // 截取模型封面图
    screenshot() {
      this.canvasDialogVisible = true;
      this.zhe_zhao = true;
      let imgUrl = localStorage.getItem("takeCover");
      this.jietu_url = this.ruleForm.fileUrl;
      this.point_text1 = "将模型拖拽到合适角度后双击进行截图";
      this.point_text2 = "若截图失败，请尝试使用chrome浏览器进行截图！";
      this.point_text3 = "（点击关闭提示）";
    },
    closeCanvas() {
      console.log("点击了关闭");
      this.canvasDialogVisible = false;
      this.jietu_url = "";
    },
    //图片上传成功后
    handleUploadSuccess(data) {
      this.cropperModel = false;
    },
    clipSrcPic() {
      console.log("裁剪图片");
      this.cropperModel = true;
      coverGet().then((res) => {
        if (res.code == 200 && res.result !== "") {
          this.img_url = res.result;
          this.option.img = res.result;
          this.closeCanvas();
          this.cropperModel = true;
          this.$refs.cropper.startCrop();
        } else {
          this.point_text1 = "请双击模型，先截图，后裁图！";
          this.point_text2 = "（点击关闭提示）";
          this.point_text3 = "";
        }
      });
    },
    getImg() {
      console.log("双击获取图片");
    },
    // 预览--复制模型地址
    copyUrl() {
      var input = document.createElement("input"); // 创建input对象
      input.value = this.iframeUrl; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("成功复制地址");
    },
    // 获取列表数据
    getData() {
      let data = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        title: this.titleValue,
      };
      this.loading = true;
      blendQueryPage(data).then((res) => {
        this.loading = false;
        this.tableData = res.data.records;
        this.tableData.forEach((el) => {
          el.status = el.status == 0 ? false : true;
        });
        this.total = res.data.total;
      });
    },
    // 状态修改
    isSwitch(item) {
      // let type = item.status ? 0 : 1;
      blendSwitchExplore({ Bid: item.id, status: item.status ? 0 : 1 }).then(
        (res) => {
          if (res.code == 200) {
            this.$message.success("切换成功");
            this.getData();
          } else {
            this.$message.warning(res.message);
          }
        }
      );
    },
    // 上传应用--提交
    submitOk() {
      let that = this;
      if (that.stepsActive == 1) {
        // console.log(that.isUploadType,'that.isUploadType');
        // that.beforeAvatarUploadZip()
        // if(!that.isUploadType){
        //   return
        // }
        if (that.fileListZip.length == 0) {
          this.$message.warning("请先上传文件！");
          return;
        }
        // return
        // console.log(that.fileListZip, "");
        // let data = {
        //   cipher: 20,
        //   file: that.fileListZip[0].url,
        //   life: 90,
        //   model:
        //     "ANGLE (AMD, Radeon RX 580 Series Direct3D11 vs_5_0 ps_5_0, D3D11)",
        //   page: "ui-none",
        //   permission: 30,
        //   size: that.fileListZip[0].size,
        //   type: "zip",
        //   userAgent:
        //     "mozilla/5.0 (windows nt 10.0; win64; x64) applewebkit/537.36 (khtml, like gecko) chrome/111.0.0.0 safari/537.36 edg/111.0.1661.44",
        //   vendor: "Google Inc. (AMD)",
        // };
        that.$refs.uploadZip.submit();
        that.centerDialogVisible = false;
        that.dialogVisiblePoint = true;
        that.surplusTimer = parseInt(
          that.fileListZip[0].size / 1024 / 1024 / 2
        );
        if (that.surplusTimer < 5) {
          that.surplusTimer = 5;
        }
        that.ruleForm.fileSize = parseInt(that.fileListZip[0].size / 1024);
        let timer = setInterval(() => {
          that.surplusTimer--;
          if (that.surplusTimer < 1) {
            clearInterval(timer);
          }
        }, 1000);
        // 判断zip包--频繁上传
        // offLineQueryNo().then((res) => {
        //   if (res.code == 200) {
        //     that.$refs.uploadZip.submit();
        //     that.centerDialogVisible = false;
        //     that.dialogVisiblePoint = true;
        //     that.surplusTimer = parseInt(
        //       that.fileListZip[0].size / 1024 / 1024 / 2
        //     );
        //     if (that.surplusTimer < 5) {
        //       that.surplusTimer = 5;
        //     }
        //     that.ruleForm.fileSize = parseInt(that.fileListZip[0].size / 1024);
        //     let timer = setInterval(() => {
        //       that.surplusTimer--;
        //       if (that.surplusTimer < 1) {
        //         clearInterval(timer);
        //       }
        //     }, 1000);
        // 上传zip
        // offLineZip(data).then((res) => {
        //   console.log(res.data);
        //   if (res.code == 200) {
        //     clearInterval(timer);
        //     that.ruleForm.fileUrl = res.result[0];
        //     // 添加
        //     if (that.isEdit == 0) {
        //       that.$message.success("上传成功");
        //       that.stepsActive = 2;
        //       that.dialogVisiblePoint = false;
        //       that.centerDialogVisible = true;
        //     } else if (that.isEdit == 1) {
        //       // 编辑
        //       that.ruleForm.Bid = that.ruleForm.id;
        //       that.ruleForm.dueTim = "0";
        //       console.log(that.ruleForm, "that.ruleForm");
        //       blendUpdate(that.ruleForm).then((res) => {
        //         that.dialogVisiblePoint = false;
        //         if (res.code == 200) {
        //           that.$message.success("保存成功");
        //           that.centerDialogVisible = false;
        //         } else {
        //           that.$message.warning(res.message);
        //         }
        //       });
        //       that.dialogVisiblePoint = false;
        //     }
        //   } else {
        //     that.$message.error(res.message);
        //     clearInterval(timer);
        //   }
        // });
        //   } else {
        //     that.$message.warning(res.message);
        //   }
        // });
      } else if (that.stepsActive == 2) {
        let str = JSON.parse(localStorage.getItem("info"));
        that.ruleForm.createBy = str.userName;
        if (this.StorageTime == "") {
          this.ruleForm.dueTim = "1825";
        } else {
          this.ruleForm.dueTim = this.StorageTime;
        }
        console.log(this.ruleForm, "this.ruleForm22");
        var link =
          document.querySelector("link[rel*='icon']") ||
          document.createElement("link");
        that.ruleForm.ico = link.href;
        // console.log(this.ruleForm, "this.ruleForm");
        if (that.isEdit == 0) {
          this.ruleForm.blendType = "1";
          blendAdd(that.ruleForm).then((res) => {
            if (res.code == 200) {
              that.$message.success("保存成功");
              that.centerDialogVisible = false;
              this.ruleForm = {};
              that.getData();
            } else {
              that.$message.warning(res.message);
            }
          });
        } else if (that.isEdit == 1) {
          that.ruleForm.Bid = that.ruleForm.id;
          blendUpdate(that.ruleForm).then((res) => {
            if (res.code == 200) {
              that.$message.success("保存成功");
              that.centerDialogVisible = false;
              that.getData();
            } else {
              that.$message.warning(res.message);
            }
          });
        }
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getData();
    },
    openLog(item, i) {
      //   this.type = true;
      if (i == 0) {
        // this.centerDialogVisible = true;
        // this.fileListZip = [];
        // this.ruleForm = item;
        // this.stepsActive = 1;
        // this.isEdit = 1;
        this.centerDialogVisible = true;
        this.fileListZip = [];
        let str = JSON.stringify(item);
        this.ruleForm = JSON.parse(str);
        this.ruleForm.blendType = this.ruleForm.blendType * 1;
        this.ruleForm.typeId = this.ruleForm.typeId * 1;
        this.StorageTime = this.surplusDay(this.ruleForm.dueTim);
        this.stepsActive = 1;
        this.isEdit = 1;
        this.fileList = [{ name: "img", url: item.icon }];
      } else if (i == 1) {
        console.log(item, "item");
        this.centerDialogVisible = true;
        this.fileListZip = [];
        let str = JSON.stringify(item);
        this.ruleForm = JSON.parse(str);
        this.ruleForm.blendType = this.ruleForm.blendType * 1;
        this.ruleForm.typeId = this.ruleForm.typeId * 1;
        // if(this.ruleForm.password!==''||this.StorageTime!==''||this.ruleForm.remark!==''||this.ruleForm.webTitle!==''||this.ruleForm.webIcon!==''){
        //   this.switchSeting=true;
        // }else{
        //   this.switchSeting=false;
        // }
        this.switchSeting = true;
        this.StorageTime = this.surplusDay(this.ruleForm.dueTim);
        this.stepsActive = 2;
        this.isEdit = 1;
        this.fileList = [{ name: "img", url: item.icon }];
        this.fileList1 = [{ name: "img1", url: item.webIcon }];
      } else if (i == 2) {
        this.previewDialogVisible = true;
        this.iframeUrl = item.realUrl;
        this.iframeTitle = item.title;
      } else if (i == 3) {
        blendDeleteById({ Bid: item.id }).then((res) => {
          if (res.code == 200) {
            this.$message.success("删除成功");
            this.getData();
          }
        });
      }
    },
    /**@method 上传时回调 */
    handProgress(event, file, fileList) {
      if (this.isUpdate) {
        this.$message.closeAll();
        this.$message({
          message: "正在上传",
        });
      }
      this.isUpdate = false;
    },
    /**@method 上传成功 */
    handleAvatarSuccessZip(res, file) {
      this.$message.closeAll();
      // this.$message({
      //   message: "上传成功",
      //   type: "success",
      // });
      this.isUpdate = true;
      if (res) {
        if (res.code == 200) {
          this.ruleForm.fileUrl = res.result[0];
          this.ruleForm.Bid = this.ruleForm.id;
          // if (this.StorageTime == "") {
          //   this.ruleForm.dueTim = "1825";
          // } else {
          //   this.ruleForm.dueTim = this.StorageTime;
          // }
          this.ruleForm.dueTim = this.StorageTime;
          console.log(this.ruleForm, "this.ruleForm");
          blendUpdate(this.ruleForm)
            .then((data) => {
              this.dialogVisiblePoint = false;
              if (data.code == 200) {
                this.$message.success("保存成功");
              } else {
                this.$message.warning(data.message);
                this.centerDialogVisible = true;
              }
            })
            .catch((err) => {
              this.dialogVisiblePoint = false;
              this.centerDialogVisible = true;
            });
        } else {
          this.$message.error("保存失败!" + res.message);
          this.dialogVisiblePoint = false;
        }
      }

      console.log(res, "res-----------");
    },
    beforeAvatarUploadZip(file) {
      const isLt2M = file.size / 1024 / 1024 < 200;
      if (!isLt2M) {
        this.$message.error("zip压缩包大小不能超过 200MB!");
      }
      return isLt2M;
    },
    //上传文件事件
    handleChangeZip(file, fileList) {
      if (file.status != "ready") {
        return false;
      } //文件准备好后再执行 解决两次调用的问题
      this.beforeAvatarUploadZip(file, fileList); //进行文件校验  （自动上传配合 before-upload 使用，）手动上传需要单独校验
      // fileList.forEach((item) => {
      //   //循环数组进行base64转换
      //   //调用base64方法进行数据的转换(方法在下面定义)
      //   this.getBase64(item.raw).then((res) => {
      //     item.url = res; //在原数组中每个数据添加属性url和转换后的base64编码值（这里我放在原字段中方便删除文件列表使用,也可以自定义数组存放根据个人喜好）
      //   });
      // });
      // 转换base64是异步方式需要一些时间  这里赋值建议延迟一下
      this.fileListZip = fileList;
    },
    // 封面
    handleAvatarSuccessCoverImg(res, file, fileList) {
      this.fileList = fileList;
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.ruleForm.icon = `https://cdn-oss.jquen.com/${res.data}`;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.ruleForm.icon = "";
      this.fileList = [];
    },
    // 浏览器图标
    handleAvatarSuccessIcon(res, file, fileList) {
      this.fileList1 = fileList;
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.ruleForm.webIcon = `https://cdn-oss.jquen.com/${res.data}`;
    },
    handleRemoveIcon(file, fileList) {
      console.log(file, fileList);
      this.ruleForm.webIcon = "";
      this.fileList1 = [];
    },
    // 转base64码
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        let fileResult = "";
        reader.readAsDataURL(file);
        // 开始转
        reader.onload = () => {
          fileResult = reader.result;
        };
        // 转 失败
        reader.onerror = (error) => {
          reject(error);
        };
        // 转 结束
        reader.onloadend = () => {
          resolve(fileResult);
        };
      });
    },
    // 计算剩余天数
    surplusDay(timer) {
      console.log(timer, "timer9999");
      let d1 = Date.parse(new Date());
      let d2 = Date.parse(new Date(timer));
      // 时间戳相减 / 天数
      let day = parseInt((d2 - d1) / (1000 * 60 * 60 * 24));
      return day;
    },
  },
};
</script>
  
  <style lang="scss" scoped>
::v-deep.avatar-iconImg {
  .el-upload--picture-card {
    position: relative;
    width: 32px;
    height: 32px;
  }
  .avatar-uploader-icon {
    font-size: 18px;
    width: 32px;
    height: 32px;
    line-height: 32px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
}
.cropper-box {
  width: 100%;
  height: 500px;
  position: relative;
  .el-button {
    position: absolute;
    bottom: 30px;
    right: 30px;
  }
}
.canvas-box {
  width: 100%;
  height: 720px;
  position: relative;

  .zhezhao {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75);
    position: absolute;
    left: 0px;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  iframe {
    width: 100%;
    height: 100%;
  }

  .el-button {
    position: absolute;
    left: 50%;
    bottom: 30px;
    transform: translateX(-50%);
  }
  .img-box {
    width: 300px;
    height: 240px;
    position: absolute;
    right: -326px;
    top: 0px;
  }
}

.dialog-box {
  user-select: none;

  ::v-deep.el-dialog {
    box-shadow: none;
    background: transparent;
  }
}

.handset-box {
  width: 375px;
  height: 766px;
  background-image: url(../../../assets/images/iphonexr.png);
  background-size: cover;
  padding: 161px 14px 82px 11px;
  position: relative;

  // background-color: #ffffff;
  .title {
    position: absolute;
    left: 53px;
    top: 132px;
    width: 160px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    color: #2a2a2a;
    font-size: 17px;
    line-height: 18px;
  }

  .btn-box {
    width: 100%;
    position: absolute;
    left: 0px;
    top: 128px;

    img {
      width: 60px;
      height: 60px;
      display: block;
      margin-bottom: 10px;
      cursor: pointer;
    }

    .close {
      width: 38px;
      height: 28px;
      position: absolute;
      top: 0px;
      right: 28px;
      border-radius: 0px 50px 50px 0;
      cursor: pointer;
    }

    .title {
      width: 160px;
      height: 24px;
      position: absolute;
      top: 0px;
      left: 50px;
      cursor: pointer;
    }
  }

  iframe {
    width: 100%;
    height: 100%;
    background-color: #ffffff !important;
  }
}

::v-deep.dialog-box {
  .el-dialog {
    position: absolute !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0 !important;
  }
}
::v-deep.dialog-boxs {
  .el-dialog {
    position: absolute !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0 !important;
    max-height: 70vh;
    overflow-y: scroll;
  }
  .el-dialog::-webkit-scrollbar {
    width: 0 !important;
  }

  /*IE 10+*/
  .el-dialog {
    -ms-overflow-style: none;
  }

  /*Firefox*/
  .el-dialog {
    overflow: -moz-scrollbars-none;
  }

  .el-dialog__header {
    position: sticky;
    background-color: #ffffff;
    top: 0px;
    z-index: 99999999;
  }
  .el-dialog__footer {
    position: sticky;
    background-color: #ffffff;
    bottom: 0px;
    z-index: 99999999;
  }
}

::v-deep.upload-zip {
  width: 100%;
  height: 120px;
  border-radius: 12px;
  border: 1px dashed #dcdddd;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 148px;
  text-align: center;
}

::v-deep .avatar-uploader .el-upload--text {
  width: auto;
  height: auto;
  border: none;

  p {
    color: $color-1;
    text-decoration: underline;
  }
}

::v-deep .avatar-coverImg .el-upload--text {
  width: 100px;
  height: 100px;
}

::v-deep .avatar-coverImg .avatar-uploader-icon {
  line-height: 100px;
}

.select-goods-list {
  margin-bottom: 20px;
  border-radius: 40px;
  box-sizing: border-box;
  padding: 0 20px;
  background: #ffffff;
  transition: all 0.4s;
}

.select-goods-list:hover {
  background: #f5f5f6;
  transition: all 0.4s;
  cursor: pointer;
}

::v-deep .disabled .el-upload--picture-card {
  display: none;
}

::v-deep .avatar-coverImg .el-upload--picture-card {
  width: 200px;
  height: 160px;
}

::v-deep .avatar-coverImg .el-upload-list__item {
  width: 200px;
  height: 160px;
}

::v-deep .el-switch__core {
  cursor: pointer !important;
}

// ::v-deep .el-upload--picture-card {
//   width: 200px;
//   height: 160px;
// }

::v-deep .vue-cropper {
  background: #f0f0f0;
}
</style>